<template>
    <ui-create
        name="table"
        :title="title"
        :load="load"
    >
        <template #body >
            <v-form ref="form" >
                <v-row>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <v-text-field
                            label="Capacidade"
                            v-model="form.capacidade"
                            maxlength="3"
                            v-mask="'###'"
                            :rules="[form_capacidade_rules.required, form_capacidade_rules.nan]"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-radio-group
                            label="Status"
                            v-model="form.status"
                            :rules="[form_status_rules.required]"
                        >
                            <v-radio label="Ativado" value="a"></v-radio>
                            <v-radio label="Desativado" value="d"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
            </v-form>
        </template>
    </ui-create>
</template>
<script>
import uiCreate from '@/templates/create'
export default {
    name: 'vwtableCreate',
    components: {
        uiCreate
    },
    data(){
        return {
            load: false,
            form: {
                status: null,
                capacidade: ''
            },

            sections: [],

            form_status_rules: {
                required: value => !!value || 'Status é obrigatório.'
            },

            form_capacidade_rules: {
                required: value => !!value || 'Capacidade é obrigatória.',
                nan: value => !isNaN(value) || 'Dados incorreto!'
            }
        }
    },

    computed:{
        id: function(){
            return this.$route.params.id
        },

        title(){
            return this.id ? `Editar Mesa: ${this.id}` : 'Cadastrar Mesa'
        }
    },

    methods: {
        clearForm: function() {
            for (let prop in this.form) {
                this.form[prop] = ''
            }
        },
    
        save: function(){
            if(this.$refs.form.validate()){
                this.load = true
                if(this.id !== undefined){
                    this.update()
                }else{
                    this.create()
                }
            }
        },

        update: function(){
            this.load = true
            fetch(process.env.VUE_APP_API + '/table/update/' + this.id, {
                method: 'PUT',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.clearForm()
                    this.returnRead()
                })
                .catch(error => {
                    console.error(error)
                    this.alert = {
                        text: 'Erro interno ao atualizar a mesa!',
                        show: true,
                        type: 'red darken-1'
                    }
                })
                .finally(() => {
                    this.load = false
                })
        },

        create: function(){
            this.load = true
            fetch(process.env.VUE_APP_API + '/table/create', {
                method: 'POST',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.alertSuccess = {
                        text: 'Mesa cadastrada com sucesso.',
                        show: true,
                        type: 'red darken-1'
                    }
                    this.clearForm()
                })
                .catch(error => {
                    console.error(error)
                    this.alert = {
                        text: 'Erro interno ao gravar a mesa!',
                        show: true,
                        type: 'red darken-1'
                    }
                })
                .finally(() => {
                    this.load = false
                })
        },

        getData: function(){
            this.load = true
            fetch(process.env.VUE_APP_API + '/table/read/' + this.id, {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.form = resJSON.response[0].listhelper[0]
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.load = false
                })
        },
    },

    mounted(){
        if (this.id != undefined) {
            this.getData()
        }

        this.$root.$on('save-changes-on-', () => {
            this.$nextTick(() => {
                this.save()
            })
        })
    }
}
</script>